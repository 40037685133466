import styled, { css } from 'styled-components'

import AnyLink from '~/components/any-link'
import { font } from '~/styles/utils'

export const PrimaryButton = styled.button`
  ${font('bold')};
  font-style: normal;
  font-size: 16px;
  background-color: ${({ theme }) => theme.color.background.button.default};
  border: 0;
  line-height: 48px;
  text-align: center;
  color: ${({ theme }) => theme.color.text.button.default};
  padding: 0 24px;
  margin-left: 0px;
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  z-index: 0;
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    background-color: ${({ theme }) => theme.color.background.button.hover};
    transition: 0.5s;
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.color.background.button.hover};
    transition: background-color 0.1s ease-in;
    transition-delay: 0.4s;
    &:before {
      width: 100%;
    }
  }

  && {
    ${(props) =>
      props.disabled &&
      css`
        background-color: ${({ theme }) =>
          theme.color.background.button.secondary.default};
        color: ${({ theme }) => theme.color.text.disabled};
        cursor: not-allowed;
        line-height: 46px;
        border: 2px solid ${({ theme }) => theme.color.border.disabled};

        &:before {
          content: none;
        }

        &:hover,
        &:focus {
          background-color: white;
          color: ${({ theme }) => theme.color.text.disabled};
          border: 2px solid ${({ theme }) => theme.color.border.disabled};
          &:before {
            width: 12px;
          }
        }
      `};
  }
`

export const PlainButton = styled(PrimaryButton)`
  display: inline-block;
  background: none;
  border: none;
  margin: 0;
  padding: 0 24px;
  color: ${({ theme }) => theme.color.text.link.default};
  line-height: inherit;
  &:before {
    display: none;
  }
  &:hover,
  &:focus {
    background: none;
    color: ${({ theme }) => theme.color.text.link.hover};
  }
  &:disabled {
    color: ${({ theme }) => theme.color.text.disabled};
    border: none;
  }
  .inner {
    display: inline;
  }
  .left-glyph {
    margin-right: 15px;
  }
  .right-glyph {
    margin-left: 15px;
  }
`

export const InlineButton = styled.button`
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-align: left;
`

export const LinkButton = styled(AnyLink)``

export const SecondaryButton = styled(PrimaryButton)`
  background-color: ${({ theme }) =>
    theme.color.background.button.secondary.default};
  border: 2px solid ${({ theme }) => theme.color.icon.button.secondary};
  color: ${({ theme }) => theme.color.text.button.secondary};
  box-sizing: border-box;
  line-height: 42px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    background-color: ${({ theme }) =>
      theme.color.background.button.secondary.hover};
    transition: 0.5s;
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.color.background.button.secondary};
    transition: background-color 0.1s ease-in;
    transition-delay: 0.4s;
  }
`

export const Content = styled.span`
  color: inherit;
  z-index: 1;
  position: relative;
`
