export const IcoMoonGlyphNames = [
  'adventure-assurance',
  'bag',
  'blackout-dates-solid',
  'camera-plus',
  'columns',
  'error-circle',
  'help',
  'information',
  'lodge',
  'plus-circle',
  'simple-pass',
  'skiier-plus',
  'success-solid',
  'success-thin',
]

export const FontAwesomeIcons: Record<string, string> = {
  'arrow-up-from-bracket': 'regular',
  'arrow-up-right-from-square': 'regular',
  'cake-candles': 'regular',
  'calendar-blackout-days-light': 'kit',
  camera: 'regular',
  'cc-amex': 'brands',
  'cc-discover': 'brands',
  'cc-mastercard': 'brands',
  'cc-visa': 'brands',
  check: 'regular',
  'chevron-down': 'regular',
  'chevron-left': 'regular',
  'chevron-right': 'regular',
  'chevron-up': 'regular',
  'circle-b': 'duotone',
  'circle-check': 'regular',
  'circle-chevron-up': 'regular',
  'circle-info': 'regular',
  'circle-plus': 'regular',
  'credit-card': 'regular',
  'confidence-to-buy-light': 'kit',
  'ellipsis-vertical': 'regular',
  envelope: 'regular',
  'eye-slash': 'regular',
  eye: 'regular',
  facebook: 'brands',
  'flag-cdn-light': 'kit',
  'flag-usa-light': 'kit',
  gift: 'light',
  heart: 'regular',
  house: 'regular',
  'id-badge': 'light',
  instagram: 'brands',
  link: 'regular',
  list: 'regular',
  'lock-keyhole': 'regular',
  'magnifying-glass': 'regular',
  'message-lines': 'regular',
  minus: 'regular',
  mountain: 'regular',
  pen: 'regular',
  phone: 'regular',
  plus: 'regular',
  star: 'solid',
  tag: 'light',
  'triangle-exclamation': 'regular',
  user: 'regular',
  'x-twitter': 'brands',
  xmark: 'regular',
  youtube: 'brands',
}
